var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _vm.showViewAsCustomer
                ? _c("ViewAsCustomer", { staticClass: "mt-5 ml-2" })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4", sm: "6", md: "5" } },
                        [
                          _c("v-card-title", { staticClass: "ml-2" }, [
                            _vm._v(
                              "\n              Global Suppression File\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.results.length > 0
                        ? _c(
                            "v-col",
                            { attrs: { cols: "3", md: "5" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-3 ml-10",
                                  attrs: {
                                    color: "accent",
                                    disabled: _vm.selected.length === 0
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateGlobalSuppression()
                                    }
                                  }
                                },
                                [_vm._v("\n              Update\n            ")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-col",
                            { attrs: { cols: "3", md: "5" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-3",
                                  attrs: { color: "accent" },
                                  on: {
                                    click: function($event) {
                                      return _vm.validateAddGlobalSuppression()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Add global suppression\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3", md: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-3 ml-3",
                              attrs: {
                                color: "primary",
                                to: "emailSuppression"
                              }
                            },
                            [
                              _vm._v(
                                "\n              Back To main\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "mt-10",
                    attrs: {
                      "item-key": "suppressionCode",
                      headers: _vm.suppressionGlobalHeaders,
                      items: _vm.results,
                      loading: _vm.resultsLoading,
                      "single-select": "",
                      "show-select": "",
                      "checkbox-color": "blue"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.data-table-select",
                          fn: function(ref) {
                            var isSelected = ref.isSelected
                            var select = ref.select
                            return [
                              _c("v-simple-checkbox", {
                                directives: [
                                  { name: "ripple", rawName: "v-ripple" }
                                ],
                                attrs: { color: "primary", value: isSelected },
                                on: {
                                  input: function($event) {
                                    return select($event)
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.createDate",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("displayDateFormat")(
                                        item.createDate
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.updateDate",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("displayDateFormat")(
                                        item.updateDate
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.totalRecords",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [_vm._v(_vm._s(item.totalRecords))])
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.recordsOnLastUpdate",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s(item.recordsOnLastUpdate))
                                ])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }