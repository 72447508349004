<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <ViewAsCustomer
          v-if="showViewAsCustomer"
          class="mt-5 ml-2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-row no-gutters>
            <v-col
              cols="4"
              sm="6"
              md="5"
            >
              <v-card-title class="ml-2">
                Global Suppression File
              </v-card-title>
            </v-col>
            <v-col
              v-if="results.length > 0"
              cols="3"
              md="5"
            >
              <v-btn
                class="mt-3 ml-10"
                color="accent"
                :disabled="selected.length === 0"
                @click="updateGlobalSuppression()"
              >
                Update
              </v-btn>
            </v-col>
            <v-col
              v-else
              cols="3"
              md="5"
            >
              <v-btn
                class="mt-3"
                color="accent"
                @click="validateAddGlobalSuppression()"
              >
                Add global suppression
              </v-btn>
            </v-col>
            <v-col
              cols="3"
              md="2"
            >
              <v-btn
                class="mt-3 ml-3"
                color="primary"
                to="emailSuppression"
              >
                Back To main
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            item-key="suppressionCode"
            class="mt-10"
            :headers="suppressionGlobalHeaders"
            :items="results"
            :loading="resultsLoading"
            single-select
            show-select
            checkbox-color="blue"
          >
            <template v-slot:[`item.data-table-select`]="{isSelected,select}">
              <v-simple-checkbox
                v-ripple
                color="primary"
                :value="isSelected"
                @input="select($event)"
              />
            </template>
            <template v-slot:[`item.createDate`]="{ item }">
              <div>
                <span>{{ item.createDate | displayDateFormat }}</span>
              </div>
            </template>
            <template v-slot:[`item.updateDate`]="{ item }">
              <div>
                <span>{{ item.updateDate | displayDateFormat }}</span>
              </div>
            </template>
            <template v-slot:[`item.totalRecords`]="{ item }">
              <div>
                <span>{{ item.totalRecords }}</span>
              </div>
            </template>
            <template v-slot:[`item.recordsOnLastUpdate`]="{ item }">
              <div>
                <span>{{ item.recordsOnLastUpdate }}</span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { nowwService } from '@/shared/services'
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'GlobalSuppression',

  components: {
    ViewAsCustomer: () =>
        import('@/views/pages/components/user-state/ViewAsCustomer')
  },

  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 3 || 'Min 3 characters',
        special: value => {
          const pattern = /^[a-zA-Z0-9 _-]*$/
          return pattern.test(value) || 'No special characters allowed.'
        }
      },
      suppressionCode: '',
      selected: [],
      addError: '',
      isSelected: false,
      selectedItemCode: '',
      selectedID: '',
      valid: false,
      results: [],
      addResults: '',
      resultsLoading: false,
      suppressionGlobalHeaders: [
        { text: 'Create Date', value: 'createDate', sortable: false, class: 'title', align: 'center' },
        { text: 'Update Date', value: 'updateDate', sortable: false, class: 'title', align: 'center' },
        { text: '# Of Emails', value: 'totalRecords', sortable: false, class: 'title', align: 'center' },
        { text: 'Records On Last Update', value: 'recordsOnLastUpdate', sortable: false, class: 'title', align: 'center' }
      ]
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.loadGlobalSuppression()
      }
    })
  },

  created () {
    this.loadGlobalSuppression()
  },

  methods: {
    clearErrors () {
      this.addError = ''
    },
    async loadGlobalSuppression () {
      var _this = this
      _this.resultsLoading = true
      await nowwService.getGlobalSuppression(_this.$store.getters['simulatedCustomerNowwId']())
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
            _this.resultsLoading = false
          }
        })
    },
    async validateAddGlobalSuppression () {
      var _this = this
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      _this.resultsLoading = true
      await nowwService.globalSuppressionCodeCount(customerId)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else if (resp !== 0) {
            _this.addError = 'There is already a global suppression file for this customer.'
            _this.resultsLoading = false
          } else {
            _this.codeCountResults = resp
            _this.resultsLoading = false
            _this.addGlobalSuppression()
          }
        })
    },
    async addGlobalSuppression () {
      var _this = this
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      _this.resultsLoading = true
      await nowwService.addGlobalSuppression(customerId)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.addResults = resp
            _this.resultsLoading = false
            _this.$router.push({ path: '/globalSuppressionImport',
              query: {
                cid: customerId,
                sc: _this.supressionCode,
                sid: resp }
            })
          }
        })
    },
    updateGlobalSuppression () {
      var _this = this
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      _this.resultsLoading = true
      _this.$router.push({ path: '/globalSuppressionImport',
        query: {
          cid: customerId,
          sid: _this.selectedId,
          edit: 'Y'
        }
      })
    }
  }
}
</script>
